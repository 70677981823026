import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../config/firestore";
import { Order } from "../types/Order";
import Navbar from "../components/Navbar";
import OrderTile from "../components/OrderTile";
import DeleteDialog from "../components/DeleteDialog";

const Completed = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const getOrders = async () => {
      try {
        const q = query(
          collection(db, "orders"),
          where("completed", "==", true)
        );

        const querySnapshot = await getDocs(q);
        const orders = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          lastname: doc.data().lastname,
          bday: doc.data().bday,
          email: doc.data().email,
          medicine: doc.data().medicine,
          created: doc.data().created,
          completed: doc.data().completed,
        }));
        setOrders(orders);
      } catch (e) {
        console.error(e);
      }
    };
    getOrders();
  }, []);

  const [id, setId] = useState("");

  const openDialog = async (id: string) => {
    setId(id);
    setIsOpen(true);
  };

  const deleteOrder = async (id: string) => {
    try {
      await deleteDoc(doc(db, "orders", id));
      setOrders(orders.filter((order) => order.id !== id));
    } catch (e) {
      console.error(e);
    }
    setIsOpen(false);
  };

  return (
    <div className="overflow-auto">
      <Navbar />
      <DeleteDialog
        id={id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        deleteOrder={deleteOrder}
      />
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mt-40">
        {orders.length == 0 ? (
          <h2 className="text-3xl font-normal tracking-tight text-gray-900 text-center mb-10">
            Vsa naročila so bila zaključena.
          </h2>
        ) : (
          <>
            {orders.map((order) => (
              <div key={order.id}>
                <OrderTile
                  order={order}
                  onBtnClick={openDialog}
                  btnText="Izbriši"
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Completed;
