import { Dialog } from "@headlessui/react";

interface DialogProp {
  id: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  deleteOrder: (id: string) => Promise<void>;
}

const DeleteDialog = ({ isOpen, setIsOpen, deleteOrder, id }: DialogProp) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      <div
        className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-black/30"
        aria-hidden="true"
      >
        <Dialog.Panel className="w-96 rounded-md bg-sky-900 p-5">
          <Dialog.Title className=" text-sky-100 font-medium text-2xl mb-10">
            Ali ste prepričani da želite izbrisati naročilo?
          </Dialog.Title>
          <div className="flex justify-between">
            <button
              onClick={() => deleteOrder(id)}
              className="w-32 rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-red-500 hover:bg-red-700"
            >
              Izbriši
            </button>
            <button
              onClick={() => setIsOpen(false)}
              className="w-32 rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-sky-600 hover:bg-sky-700"
            >
              Prekliči
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default DeleteDialog;
