import Order from "./Order";
import Navbar from "../components/Navbar";
import { useAuth } from "../context/AuthProvider";
import UserNavbar from "../components/UserNavbar";
import Footer from "../components/Footer";

const Home = () => {
  const { user } = useAuth();

  return (
    <div className="min-h-screen flex flex-col">
      {user ? <Navbar /> : <UserNavbar />}
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-40">
        <Order />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
