import { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { Order } from "../types/Order";

interface OrderProp {
  order: Order;
  onBtnClick: (id: string) => void;
  btnText: string;
}

const OrderTile = ({ order, onBtnClick, btnText }: OrderProp) => {
  let [isOpen, setIsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const copyEmail = async () => {
    try {
      await navigator.clipboard.writeText(order.email);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="mb-12">
      <Disclosure>
        <Disclosure.Button
          className="mb-2 py-2 bg-sky-900 hover:bg-sky-700 rounded-md w-full drop-shadow-md"
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          <div className="flex justify-between text-sky-100 font-medium text-xl py-2 px-4">
            <div className="flex space-x-4">
              <div>
                {order.name} {order.lastname}
              </div>
              <div>|</div>
              <div>{order.bday}</div>
              <div>|</div>
              <div>{order.email}</div>
            </div>
            <div className="flex space-x-4">
              <div>
                {order.created.toDate().getDate()}.
                {order.created.toDate().getMonth() + 1}.
                {order.created.toDate().getFullYear()}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={`w-6 h-6" ${isOpen ? "rotate-180" : ""}`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m4.5 15.75 7.5-7.5 7.5 7.5"
                />
              </svg>
            </div>
          </div>
        </Disclosure.Button>
        <Disclosure.Panel className="text-gray-800 border-2 rounded-md p-2">
          <div className="pb-5">{order.medicine}</div>
          <div className="flex space-x-20">
            <button
              className={`w-64 rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm ${
                btnText === "Zaključi"
                  ? "bg-amber-500 hover:bg-amber-700"
                  : "bg-red-500 hover:bg-red-700"
              }`}
              onClick={() => onBtnClick(order.id)}
            >
              {btnText}
            </button>
            {btnText === "Zaključi" ? (
              <button
                className={`w-64 rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-emerald-500 hover:bg-emerald-700`}
                onClick={copyEmail}
              >
                {isCopied ? "✅ Kopirano" : "Kopiraj email"}
              </button>
            ) : (
              <></>
            )}
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );
};

export default OrderTile;
