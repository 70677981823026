import { Link } from "react-router-dom";
import UserNavbar from "../components/UserNavbar";
import Footer from "../components/Footer";

const Tos = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <UserNavbar />
      <div className="h-full flex items-center justify-center mt-40 mb-10">
        <div className="max-w-7xl px-5">
          <div>
            <Link to="/" className="text-sky-600 hover:text-sky-700 text-lg">
              <div className="flex space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                  />
                </svg>
                <p>Nazaj na prvo stran</p>
              </div>
            </Link>
          </div>
          <div className="text-lg font-medium text-gray-900 mt-10 leading-2">
            Dobrodošli na naši spletni strani. S tem, ko dostopate do naše
            spletne strani in izpolnjujete spletni obrazec, se strinjate s
            pogoji uporabe in sprejemate, da ste prebrali, razumeli ter se
            strinjate z njimi v celoti. Če se ne strinjate s katerim koli delom
            teh pogojev, vas prosimo, da ne uporabljate naše spletne strani.
            Vaša zasebnost je za nas izjemno pomembna. Spodaj pojasnjujemo kako
            zbiramo, uporabljamo, razkrivamo in varujemo vaše osebne podatke na
            naši spletni strani.
          </div>
          <h2 className="text-4xl font-normal text-sky-900 text-center border-b-2 pb-2 border-sky-900 mt-10">
            Pogoji uporabe
          </h2>
          <ol className="list-decimal list-inside mt-5 space-y-4">
            <li>
              Uporaba spletnega obrazca: Spletne obrazce, ki jih izpolnjujete na
              naši spletni strani, morate izpolniti natančno in pošteno.
              Zavezujete se, da boste zagotovili resnične in točne podatke.
            </li>
            <li>
              Zdravniška presoja: Vneseni podatki se posredujejo zdravniku, ki
              preveri naročilo in predpiše ustrezno zdravilo. Zdravnik ima
              dostop do teh podatkov izključno za medicinske namene.
            </li>
            <li>
              Varovanje zasebnosti: Vaša zasebnost je za nas izjemno pomembna.
              Vsi podatki, ki jih posredujete prek naše spletne strani, bodo
              obravnavani v skladu z našo politiko zasebnosti.
            </li>
            <li>
              Omejitev odgovornosti: Naša spletna stran je na voljo "takšna, kot
              je". Ne prevzemamo nobene odgovornosti za morebitne izgube ali
              škode, ki bi izhajale iz uporabe naše spletne strani.
            </li>
            <li>
              Spremembe pogojev storitve: Pridržujemo si pravico do sprememb teh
              pogojev storitve kadarkoli. Spremembe bodo začele veljati takoj po
              objavi na tej spletni strani.
            </li>
          </ol>
          <h2 className="text-4xl font-normal text-sky-900 text-center border-b-2 pb-2 border-sky-900 mt-20">
            Politika zasebnosti
          </h2>
          <ol className="list-decimal list-inside mt-5 space-y-4">
            <li>
              Zbiranje podatkov: Zbiramo osebne podatke, kot so ime, priimek,
              datum rojstva in informacije o zdravilih, ki jih potrebujete, ko
              oddate naročilo.
            </li>
            <li>
              Uporaba podatkov: Vaši osebni podatki se uporabljajo izključno za
              zagotavljanje storitev, ki jih ponuja naša spletna stran, kot je
              posredovanje naročil zdravil zdravnikom.
            </li>
            <li>
              Razkritje podatkov: Vaši osebni podatki ne bodo posredovani
              tretjim osebam ali organizacijam, razen če je to potrebno za
              izpolnitev storitve, ki ste jo zahtevali, ali če to zahteva zakon.
            </li>
            <li>
              Varovanje podatkov: Vaši osebni podatki se varujejo pred
              nepooblaščenim dostopom, uporabo ali razkritjem. Vse informacije,
              ki jih posredujete, se hranijo na varnih strežnikih.
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Tos;
