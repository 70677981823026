import { Transition } from "@headlessui/react";

interface AlertProp {
  show: boolean;
}

const ErrorAlert = ({ show }: AlertProp) => {
  return (
    <Transition
      show={show}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="bg-red-200 border border-red-500 rounded-md p-5 text-center mb-10 text-red-600 text-xl font-bold transition-opacity duration-1000 ease-in-out">
        Napaka pri pošiljanju podatkov. Poskusite znova kasneje.
      </div>
    </Transition>
  );
};

export default ErrorAlert;
