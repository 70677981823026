import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import MobileNavbar from "./MobileNavbar";
import { useState } from "react";

const Navbar = () => {
  const { logout } = useAuth();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const logOut = async () => {
    try {
      await logout();
      navigate("/");
    } catch (e) {
      console.log(e);
    }
  };

  const showMobile = () => {
    setShow((prev) => !prev);
  };

  return (
    <nav className="bg-sky-900 shadow-md fixed w-full z-10 top-0">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="flex justify-between py-5">
          <div className="flex space-x-4">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "bg-sky-600 rounded-md"
                  : "hover:bg-sky-700 rounded-md"
              }
            >
              <div className="flex items-center text-sky-100 font-medium text-xl rounded-md p-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
                <span>Domov</span>
              </div>
            </NavLink>

            <div className="hidden md:flex items-center space-x-3">
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  isActive
                    ? "bg-sky-600 rounded-md"
                    : "hover:bg-sky-700 rounded-md"
                }
              >
                <div className="text-sky-100 font-medium text-xl rounded-md p-2">
                  Naročila
                </div>
              </NavLink>
              <NavLink
                to="/completed"
                className={({ isActive }) =>
                  isActive
                    ? "bg-sky-600 rounded-md"
                    : "hover:bg-sky-700 rounded-md"
                }
              >
                <div className="text-sky-100 font-medium text-xl rounded-md p-2">
                  Zaključeno
                </div>
              </NavLink>
            </div>
          </div>
          <div className="hidden md:flex items-center">
            <div
              className="text-sky-100 font-medium text-xl hover:bg-sky-700 rounded-md p-2"
              onClick={logOut}
            >
              Odjava
            </div>
          </div>
          <div className={"md:hidden flex items-center"}>
            <button onClick={showMobile}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8 text-sky-100"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* mobile menu */}
      <div className={`md:hidden ${show ? "block" : "hidden"}`}>
        <MobileNavbar />
      </div>
    </nav>
  );
};

export default Navbar;
