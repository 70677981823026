import { SubmitHandler, useForm } from "react-hook-form";
import { useAuth } from "../context/AuthProvider";
import { Link, useNavigate } from "react-router-dom";

type FormFields = {
  email: string;
  password: string;
};

const Login = () => {
  const { user, login } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormFields>();

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    try {
      await login(data.email, data.password);
      navigate("/dashboard");
    } catch (e) {
      console.error(e);
      setError("root", {
        message: "Napaka pri prijavi",
      });
    }
    reset();
  };

  return (
    <div className="h-screen flex items-center justify-center">
      <div className="w-3/4 lg:w-1/4 border-2 rounded-md p-10">
        {user ? (
          <div>
            Ste že prijavljeni.{" "}
            <Link to="/dashboard" className="text-sky-600 hover:text-sky-700">
              nazaj na stran
            </Link>
          </div>
        ) : (
          <>
            <h2 className="text-2xl font-normal tracking-tight text-gray-900 text-center">
              Prijava za admine
            </h2>
            <div className="mt-10 mb-2 mr-2 ml-2">
              <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label
                    htmlFor="email"
                    className="text-sm font-medium leading-6 text-gray-900"
                  >
                    Email naslov
                  </label>
                  <div className="mt-2">
                    <input
                      {...register("email", {
                        required: "Vnesite email",
                      })}
                      id="email"
                      name="email"
                      type="email"
                      className="w-full rounded-md ring-1 ring-gray-300 p-1.5 text-gray-900"
                    />
                    {errors.email && (
                      <div className="text-red-400">{errors.email.message}</div>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="text-sm font-medium leading-6 text-gray-900"
                  >
                    Geslo
                  </label>
                  <div className="mt-2">
                    <input
                      {...register("password", {
                        required: "Vnesite geslo",
                      })}
                      autoComplete="off"
                      id="password"
                      name="password"
                      type="password"
                      className="w-full rounded-md ring-1 ring-gray-300 p-1.5 text-gray-900"
                    />
                    {errors.password && (
                      <div className="text-red-400">
                        {errors.password.message}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  {errors.root && (
                    <div className="text-red-400 pb-2">
                      {errors.root.message}
                    </div>
                  )}
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full rounded-md bg-sky-900 hover:bg-sky-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
                  >
                    {isSubmitting ? "Nalaganje..." : "Potrdi"}
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
