import { SubmitHandler, useForm } from "react-hook-form";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./../config/firestore";
import { useState } from "react";
import SuccessAlert from "../components/SuccessAlert";
import { Link } from "react-router-dom";
import ErrorAlert from "../components/ErrorAlert";

type FormFields = {
  name: string;
  lastname: string;
  bday: string;
  email: string;
  medicine: string;
  checked: boolean;
};

const Order = () => {
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormFields>();

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    const newData = {
      ...data,
      completed: false,
      created: serverTimestamp(),
    };

    try {
      await addDoc(collection(db, "orders"), {
        ...newData,
      });
      setShow(true);
      setTimeout(() => setShow(false), 3000);
    } catch (e) {
      console.error("Error adding document: ", e);
      setShowError(true);
      setTimeout(() => setShowError(false), 5000);
    }
    reset();
  };

  return (
    <div>
      <h2 className="text-4xl font-normal text-sky-900 text-center border-b-2 pb-2 border-sky-900">
        Naročanje zdravil
      </h2>
      <p className="mt-5 mx-2 text-xl pb-2 border-b-2 border-sky-900 leading-10">
        Dobrodošli na naši spletni strani, kjer lahko enostavno naročite
        zdravila, ki jih potrebujete. Naš cilj je zagotoviti enostaven,
        zanesljiv in diskreten način za dostop do vaših zdravil brez
        nepotrebnega truda. Kako deluje? Preprosto izpolnite obrazec z
        informacijami o zdravilu, ki ga potrebujete, ter vašimi osebnimi
        podatki. Zdravnik bo pregledal vaše naročilo in vam predpisala potrebno
        zdravilo. Poleg enostavne in hitre storitve vam nudimo tudi visoko
        stopnjo varnosti in zaupnosti. Vaše osebne informacije bodo vedno
        varovane in obravnavane s strogo zaupnostjo. Po naročilu bodo recepti
        poslani v lekarno v najkrajšem možnem času.
      </p>
      <div className="mt-5 mb-10 mx-2">
        <h3 className="text-xl font-normal text-sky-900 mb-6">
          Izpolnite obrazec, da naročite zdravila.
        </h3>
        <SuccessAlert show={show} />
        <ErrorAlert show={showError} />
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col space-y-4 justify-evenly">
            <div className="w-full">
              <label
                htmlFor="name"
                className="text-sm font-medium leading-6 text-gray-900"
              >
                Ime
              </label>
              <div className="mt-2">
                <input
                  {...register("name", {
                    required: "Vnesite ime",
                  })}
                  id="name"
                  type="text"
                  className="w-full rounded-md ring-1 ring-gray-300 p-1.5 text-gray-900"
                />
                {errors.name && (
                  <div className="text-red-400">{errors.name.message}</div>
                )}
              </div>
            </div>

            <div className="w-full">
              <label
                htmlFor="lastName"
                className="text-sm font-medium leading-6 text-gray-900"
              >
                Priimek
              </label>
              <div className="mt-2">
                <input
                  {...register("lastname", {
                    required: "Vnesite priimek",
                  })}
                  id="lastName"
                  type="text"
                  className="w-full rounded-md ring-1 ring-gray-300 p-1.5 text-gray-900"
                />
                {errors.lastname && (
                  <div className="text-red-400">{errors.lastname.message}</div>
                )}
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="bday"
                className="text-sm font-medium leading-6 text-gray-900"
              >
                Datum rojstva
              </label>
              <div className="mt-2">
                <input
                  {...register("bday", {
                    pattern: {
                      value: /[^a-zA-Z]/,
                      message: "Črke niso dovoljene!",
                    },
                    required: "Vnesite datum rojstva",
                  })}
                  id="bday"
                  type="text"
                  className="w-full rounded-md ring-1 ring-gray-300 p-1.5 text-gray-900"
                />
                {errors.bday && (
                  <div className="text-red-400">{errors.bday.message}</div>
                )}
              </div>
            </div>

            <div className="w-full">
              <label
                htmlFor="email"
                className="text-sm font-medium leading-6 text-gray-900"
              >
                Email naslov
              </label>
              <div className="mt-2">
                <input
                  {...register("email", {
                    required: "Vnesite email naslov",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Vnesena vrednost se ne ujema z obliko e-pošte",
                    },
                  })}
                  id="name"
                  type="email"
                  className="w-full rounded-md ring-1 ring-gray-300 p-1.5 text-gray-900"
                />
                {errors.email && (
                  <div className="text-red-400">{errors.email.message}</div>
                )}
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="medicine"
              className="text-sm font-medium leading-6 text-gray-900"
            >
              Vnesite imena vseh zdravil, ki jih potrebujete
            </label>
            <div className="mt-2">
              <textarea
                {...register("medicine", {
                  required: "Vnesite imena zdravil",
                })}
                className="w-full min-h-36 rounded-md ring-1 ring-gray-300 p-1.5 text-gray-900"
                id="medicine"
              />
              {errors.medicine && (
                <div className="text-red-400">{errors.medicine.message}</div>
              )}
            </div>
          </div>

          <div>
            <div className="mt-2 flex space-x-2 items-center">
              <input
                {...register("checked", {
                  required: "Označite to polje, če želite nadaljevati",
                })}
                id="checked"
                type="checkbox"
                className="w-5 h-5"
              />
              <div className="text-sm font-medium leading-6 text-gray-900">
                Strinjam se s{" "}
                <Link to="/tos" className="text-sky-600 hover:text-sky-700">
                  pogoji uporabe
                </Link>
              </div>
            </div>
            {errors.checked && (
              <div className="text-red-400">{errors.checked.message}</div>
            )}
          </div>

          <div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full rounded-md bg-sky-900 hover:bg-sky-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm"
            >
              {isSubmitting ? "Nalaganje..." : "Pošlji"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Order;
