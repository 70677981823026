import { Transition } from "@headlessui/react";

interface AlertProp {
  show: boolean;
}

const SuccessAlert = ({ show }: AlertProp) => {
  return (
    <Transition
      show={show}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="bg-green-200 border border-green-600 rounded-md p-5 text-center mb-10 text-green-600 text-xl font-bold transition-opacity duration-1000 ease-in-out">
        Podatki so bili uspešno poslani!
      </div>
    </Transition>
  );
};

export default SuccessAlert;
