import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../config/firestore";
import { Order } from "../types/Order";
import Navbar from "../components/Navbar";
import OrderTile from "../components/OrderTile";

const Dashboard = () => {
  const [orders, setOrders] = useState<Order[]>([]);

  useEffect(() => {
    const getOrders = async () => {
      try {
        const q = query(
          collection(db, "orders"),
          where("completed", "==", false),
          orderBy("created", "desc")
        );
        const querySnapshot = await getDocs(q);
        const orders = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          lastname: doc.data().lastname,
          bday: doc.data().bday,
          email: doc.data().email,
          medicine: doc.data().medicine,
          created: doc.data().created,
          completed: doc.data().completed,
        }));
        setOrders(orders);
      } catch (e) {
        console.error(e);
      }
    };
    getOrders();
  }, []);

  const completeOrder = async (id: string) => {
    try {
      const idRef = doc(db, "orders", id);
      await updateDoc(idRef, { completed: true });
      setOrders(orders.filter((order) => order.id !== id));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="overflow-auto">
      <Navbar />
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 mt-40">
        {orders.length == 0 ? (
          <h2 className="text-3xl font-normal tracking-tight text-gray-900 text-center mb-10">
            Trenutno ni novih naročil zdravil.
          </h2>
        ) : (
          <>
            {orders.map((order) => (
              <div key={order.id}>
                <OrderTile
                  order={order}
                  onBtnClick={completeOrder}
                  btnText="Zaključi"
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
