import { Link } from "react-router-dom";

const MobileNavbar = () => {
  return (
    <div>
      <Link to="/">
        <div className="flex items-center text-sky-100 font-medium text-xl hover:bg-sky-700 rounded-md p-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8 mr-2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
            />
          </svg>
          <span>Domov</span>
        </div>
      </Link>
      <Link to="/dashboard">
        <div className="text-sky-100 font-medium text-xl hover:bg-sky-700 rounded-md p-2">
          Naročila
        </div>
      </Link>
      <Link to="/completed">
        <div className="text-sky-100 font-medium text-xl hover:bg-sky-700 rounded-md p-2">
          Zaključeno
        </div>
      </Link>
    </div>
  );
};

export default MobileNavbar;
