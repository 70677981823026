import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import UserNavbar from "../components/UserNavbar";

const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <UserNavbar />
      <div className="h-full flex items-center justify-center mt-40 mb-10">
        <div className="max-w-7xl px-5">
          <div>
            <Link to="/" className="text-sky-600 hover:text-sky-700 text-lg">
              <div className="flex space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                  />
                </svg>
                <p>Nazaj na prvo stran</p>
              </div>
            </Link>
          </div>
          <h2 className="text-4xl font-normal text-sky-900 text-center border-b-2 pb-2 border-sky-900 mt-10">
            Stran, ki ste jo zahtevali ne obstaja!
          </h2>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
