const Footer = () => {
  return (
    <footer className="w-full bg-sky-900 mt-auto text-center p-5">
      <p className="text-sky-100">
        Copyright &copy; 2024 ZDRAVJE - DRUŽINSKA MEDICINA, IVAN URBANC, s.p .
        Vse pravice pridržane
      </p>
    </footer>
  );
};

export default Footer;
